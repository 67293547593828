export default class Notice {
    constructor($el) {
        this.$el = $el;
        this.$close = this.$el.querySelector('.js-noticeClose');
        this.cookieKey = this.$el.dataset.noticeCookieKey;
        this.cookieValue = this.$el.dataset.noticeCookieValue;

        if (this.cookieKey && this.cookieValue) {
            if (localStorage.getItem(this.cookieKey) === this.cookieValue) {
                this.$el.classList.add('is-hidden');
            }
        }

        this.initListeners();
    }

    initListeners() {
        this.$close.addEventListener('click', this.onClose.bind(this));

        this.$el.addEventListener(
            'animationend',
            e => {
                if (e.target === this.$el) {
                    this.$el.classList.add('is-hidden');
                }
            },
            false
        );
    }

    onClose() {
        if (this.cookieKey && this.cookieValue) {
            localStorage.setItem(this.cookieKey, this.cookieValue);
        }

        this.$el.classList.add('is-hide');
    }
}
